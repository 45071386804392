<template lang="">
    <div class="flex items-center min-h-screen bg-white dark:bg-gray-900">
      <div class="container mx-auto">
          <div v-if="success" class="mx-auto my-10">
            <div class="text-center">
                <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Password successfully set.</h1>
            </div>
            <div class="m-7">
                <p class="text-sm text-center text-gray-400">Back to <a href="/login" class="text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Login</a>.</p>
            </div>
            </div>
          <div v-else class="max-w-md mx-auto my-10">
              <div class="text-center">
                  <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Set new password</h1>
              </div>
              <div class="m-7 text-black">
                  <form action="" @submit.prevent="setNewPassword">
                      <div class="mb-6">
                          <div class="flex justify-between mb-2">
                              <label for="password1" class="text-sm text-gray-600 dark:text-gray-400">Password</label>
                          </div>
                          <input v-model="password1" type="password" name="password1" id="password1" placeholder="Your Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                      </div>
                      <div class="mb-6">
                          <div class="flex justify-between mb-2">
                              <label for="password2" class="text-sm text-gray-600 dark:text-gray-400">Confirm Password</label>
                          </div>
                          <input v-model="password2" type="password" name="password2" id="password2" placeholder="Your Password" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                      </div>
                      <div class="mb-6">
                          <button type="submit" class="w-full px-3 py-4 text-white bg-eastern-blue-500 rounded-md hover:bg-eastern-blue-500 focus:bg-eastern-blue-600 focus:outline-none">Submit</button>
                      </div>
                      <p v-if="error" class="text-center text-sm text-red-500">{{ error }}</p>
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        password1: '',
        password2: '',
        error: null,
        success: false
    }
  },
  methods: {
    setNewPassword() {
        if(this.password1 === this.password2) {
            this.$store.dispatch('auth/setNewPassword', {
                uid: this.$route.params.uid,
                token: this.$route.params.token,
                new_password: this.password1
            }).then(() => {
                this.success = true
            }).catch(error => {
                if (error.response.status == 400) this.error = Object.keys(error.response.data).map(function(k){return error.response.data[k]})[0][0];
                else this.error = 'Something went wrong. Please try again.'
            })
        } else {
            this.error = 'Passwords doesnot match.'
        }
    }
  }
}
</script>
<style lang="">
    
</style>