<template lang="">
    
    <div class="sm:mt-3 p-2 pt-5 w-full">
        <div class="mt-6 max-w-sm ">
        <h1 class="font-semibold text-lg">Current Plan</h1>
        <div class="bg-ecru-white-500 p-6 rounded">
            <div v-if="plan" class="">
                <div >
                    <span class="text-lg font-bold text-black">{{ plan.plan.name }}</span>
                    <button v-if="plan.type === 'PREPAID'"  class="ml-3 px-2 py-1 bg-eastern-blue-500 border-eastern-blue-500 hover:bg-eastern-blue-600 text-white border rounded float-right" @click="$router.push({ name: 'plans'})">Renew</button>
                    <button v-else-if="plan.type === 'SUBSCRIPTION'" class="ml-3 px-2 py-1 bg-eastern-blue-500 border-eastern-blue-500 hover:bg-eastern-blue-600 text-white border rounded float-right" @click="goToCustomerPortal()">Manage Subscription</button>
                </div>
            </div>
            <div class="flex justify-between items-center"  v-else>
                <span class="text-lg font-bold text-black">Trial</span>
                <button
                    @click="$router.push({ name: 'plans' })"
                    class="
                    p-3
                    py-1
                    ml-12
                    bg-eastern-blue-500
                    text-white
                    rounded 
                    "
                >
                    Choose a plan
                </button>
            </div>
            <p v-if="!plan || plan.type == 'PREPAID'" :class="planExpired ? 'mt-6  text-sm text-red-500': 'mt-6  text-sm text-gray-600'">{{ planExpired ? 'Expired on:': 'Expiring on:' }} {{ user.plan_expiry_date }}</p>
            <p v-else-if="plan.type == 'SUBSCRIPTION'" class="mt-6  text-xs">Next bill on: {{ user.plan_expiry_date }}</p>
        </div>
    </div>
        <div class="mt-4 flex justify-between items-center">
            <h1 class=" text-lg font-semibold">Payments</h1>
        </div>
        

        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class=" overflow-hidden border-gray-200 sm:rounded-sm">
                    <table class="min-w-full border divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider">
                                    Payment ID
                                </th>
                                <th scope="col" class="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-2 text-left text-xs font-medium uppercase tracking-wider">
                                    Created on
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr :key="index" v-for="(payment, index) in payments">
                                <td class="px-6 py-2 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="">
                                            <div class="text-sm font-medium text-gray-900">
                                                {{ payment.payment_id }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-2 whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="">
                                            <div class="text-sm text-gray-500">
                                                {{ payment.currency_symbol }}{{ payment.amount }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ payment.created_on }}
                                </td>
                            </tr>

                            <!-- More people... -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </div>

</template>
<script>
export default {
    name: 'billing',
    created() {
        this.$store.dispatch("org/getPayments", {orgId: this.org.org.id});
        this.$store.dispatch("auth/getPlan");
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters['auth/isLoggedIn']
        },
        user () {
            return this.$store.getters['auth/user']
        },
        org () {
            return this.$store.getters['org/org']
        },
        payments () {
            return this.$store.getters['org/payments']
        },
        plan () {
            return this.$store.getters['auth/plan']
        },
        planExpired(){
          return new Date(this.user.plan_expiry_date) < new Date()
        }
    },
    methods: {
        redirectToCustomerPortal(){
            window.location.href = this.org.org.stripe_customer_portal_url
        },
        goToCustomerPortal(){
            this.$store.dispatch('checkout/createPortal').then((response) => {
                window.location.href = response.data.url
            })
        }
    }
}
</script>
<style lang="">
    
</style>