import axios from './http'

export default {
  createOrder (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/checkout/orders/',
        payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  getPlans () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/checkout/prices/'
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  getPlan (price_id) {
    return new Promise((resolve, reject) => {
      axios.get(
        '/checkout/prices/' + price_id
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  confirmOrder (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/checkout/orders/confirm-order/', payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  createPortal () {
    return new Promise((resolve, reject) => {
      axios.post(
        '/checkout/orders/create-customer-portal/'
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  checkStatus (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/checkout/orders/check-status/', payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  }
}
