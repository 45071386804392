var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center min-h-screen bg-white dark:bg-gray-900"},[_c('div',{staticClass:"container mx-auto"},[(_vm.success)?_c('div',{staticClass:"mx-auto my-10"},[_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"max-w-md mx-auto my-10"},[_vm._m(2),_c('div',{staticClass:"m-7 text-black"},[_c('form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.setNewPassword.apply(null, arguments)}}},[_c('div',{staticClass:"mb-6"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password1),expression:"password1"}],staticClass:"w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500",attrs:{"type":"password","name":"password1","id":"password1","placeholder":"Your Password","required":""},domProps:{"value":(_vm.password1)},on:{"input":function($event){if($event.target.composing)return;_vm.password1=$event.target.value}}})]),_c('div',{staticClass:"mb-6"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"}],staticClass:"w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500",attrs:{"type":"password","name":"password2","id":"password2","placeholder":"Your Password","required":""},domProps:{"value":(_vm.password2)},on:{"input":function($event){if($event.target.composing)return;_vm.password2=$event.target.value}}})]),_vm._m(5),(_vm.error)?_c('p',{staticClass:"text-center text-sm text-red-500"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200"},[_vm._v("Password successfully set.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-7"},[_c('p',{staticClass:"text-sm text-center text-gray-400"},[_vm._v("Back to "),_c('a',{staticClass:"text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800",attrs:{"href":"/login"}},[_vm._v("Login")]),_vm._v(".")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200"},[_vm._v("Set new password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between mb-2"},[_c('label',{staticClass:"text-sm text-gray-600 dark:text-gray-400",attrs:{"for":"password1"}},[_vm._v("Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between mb-2"},[_c('label',{staticClass:"text-sm text-gray-600 dark:text-gray-400",attrs:{"for":"password2"}},[_vm._v("Confirm Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-6"},[_c('button',{staticClass:"w-full px-3 py-4 text-white bg-eastern-blue-500 rounded-md hover:bg-eastern-blue-500 focus:bg-eastern-blue-600 focus:outline-none",attrs:{"type":"submit"}},[_vm._v("Submit")])])
}]

export { render, staticRenderFns }