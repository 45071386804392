<template lang="">
    <div class="mt-2 mx-auto sm:h-full flex justify-center items-center w-full bg-white overflow-hidden sm:rounded-lg w-full max-w-2xl">
        <form action="" @submit.prevent="updateProfile" class="w-full">
            <div class="w-full mb-4 sm:flex sm:justify-between sm:space-x-2">
                <div class="w-full">
                    <label class="block text-gray-700  mb-2" for="username">
                    First Name
                    </label>
                    <input v-model="user.first_name" type="text" name="name" id="name" placeholder="Company name" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-0 focus:outline-none focus:ring focus:ring-gray-300 rounded" />
                </div>
                <div class=" w-full mb-4">
                    <label class="block text-gray-700  mb-2" for="username">
                    Last Name
                    </label>
                    <input v-model="user.last_name" type="text" name="name" id="name" placeholder="Company name" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-0 focus:outline-none focus:ring focus:ring-gray-300 rounded" />
                </div>
            </div>
            <div class="mb-6">
                <label class="block text-gray-700 mb-2" for="username">
                Email
                </label>
                <input v-model="user.email" type="text" name="name" id="name" placeholder="Company name" required class="w-full px-2 py-2 placeholder-gray-300 border border-gray-300 bg-gray-300 rounded-0 focus:outline-none focus:ring focus:ring-gray-300 rounded" readonly />
            </div>
            <div class="mb-6 flex justify-center">
                <button type="submit" class="w-full p-2 border-eastern-blue-500 bg-eastern-blue-500 hover:bg-eastern-blue-600  text-white border rounded">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'profile',
    data(){
        return {
            user: null
        }
    },
    created() {
        this.user = this.$store.getters['auth/user']
    },
    methods: {
      updateProfile() {
        this.$store.dispatch('auth/updateProfile', {
          first_name: this.user.first_name,
          last_name: this.user.last_name
        }).then(() => {
          this.user = this.$store.getters['auth/user']
        })
      }
    }
}
</script>
<style lang="">
    
</style>