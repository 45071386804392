import axios from './http'

export default {
  getOrg () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/org/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getOrgs () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/orgs/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  inviteUser (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/orgs/' + payload.orgId + '/invite-user/',
        payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getCountries () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/countries/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPayments (payload) {
    return new Promise((resolve, reject) => {
      axios.get(
        '/orgs/payments/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInvites (payload) {
    return new Promise((resolve, reject) => {
      axios.get(
        '/orgs/invites/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setOrg (payload) {
    return new Promise((resolve, reject) => {
      var orgId = payload.org
      delete payload.org
      axios.post(
        '/users/me/orgs/'+orgId+'/', {payload}
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createOrg (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/orgs/', payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  activateUser ({userOrgId, ...payload}) {
    return new Promise((resolve, reject) => {
      axios.patch(
        `/orgs/users/${userOrgId}/`, payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deactivateUser ({userOrgId, ...payload}) {
    return new Promise((resolve, reject) => {
      axios.patch(
        `/orgs/users/${userOrgId}/`, payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  removeUser (userOrgId) {
    return new Promise((resolve, reject) => {
      axios.delete(
        `/orgs/users/${userOrgId}/`,
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
