import org from '../../api/org'

// initial state
const state = {
  org: null,
  orgs: null,
  countries: [],
  payments: [],
  invites: []
}

// getters
const getters = {
  org(state) {
    return state.org
  },
  orgs(state) {
    return state.orgs
  },
  countries(state) {
    return state.countries
  },
  payments(state) {
    return state.payments
  },
  invites(state) {
    return state.invites
  }
}

// actions
const actions = {
  getOrg({ commit }) {
    return new Promise((resolve, reject) => {
      org.getOrg().then((response) => {
        commit('setOrg', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getOrgs({ commit }) {
    return new Promise((resolve, reject) => {
      org.getOrgs().then((response) => {
        commit('setOrgs', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  inviteUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      org.inviteUser(payload).then((response) => {
        console.log(commit)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      org.getCountries().then((response) => {
        commit('setCountries', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPayments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      org.getPayments(payload).then((response) => {
        commit('setPayments', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInvites({ commit }, payload) {
    return new Promise((resolve, reject) => {
      org.getInvites(payload).then((response) => {
        commit('setInvites', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setOrg({commit}, payload) {
    return new Promise((resolve, reject) => {
      org.setOrg(payload).then(response => {
        commit('setOrg', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createOrg({commit}, payload) {
    return new Promise((resolve, reject) => {
      org.createOrg(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  activateUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      org.activateUser(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deactivateUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      org.deactivateUser(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  removeUser({commit}, payload) {
    return new Promise((resolve, reject) => {
      org.removeUser(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

// mutations
const mutations = {
  setCountries(state, data) {
    state.countries = data
  },
  setOrg(state, data) {
    state.org = data
  },
  setOrgs(state, data) {
    state.orgs = data
  },
  setPayments(state, data) {
    state.payments = data
  },
  setInvites(state, data) {
    state.invites = data
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
