<template lang="">
    <div class="w-full lg:w-2/3 xl:w-1/2 mx-auto mt-2">
        <ul class="block sm:flex w-full justify-center">
            <li class="">
                <a class="flex flex-row items-center h-8 px-4  hover:bg-eastern-blue-500 hover:text-gray-100 font-semibold cursor-pointer" v-on:click="pushRoute('dashboard')" :class="{ 'bg-eastern-blue-500 text-gray-100': activeMenu==='dashboard' }">
                    Dashboard
                </a>
            </li>
            <li class="">
                <a class="flex flex-row items-center h-8 px-4  hover:bg-eastern-blue-500 hover:text-gray-100 font-semibold cursor-pointer" v-on:click="pushRoute('organisation')" :class="{ 'bg-eastern-blue-500 text-gray-100': activeMenu==='organisation' }">
                    Organisation
                </a>
            </li>
            <li v-if="org && org.role === 'ADMIN'" class="">
                <a class="flex flex-row items-center h-8 px-4  hover:bg-eastern-blue-500 hover:text-gray-100 font-semibold cursor-pointer" v-on:click="pushRoute('billing')" :class="{ 'bg-eastern-blue-500 text-gray-100': activeMenu==='billing' }">
                    Billing
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ["isopen"],
  data() {
    return {activeMenu: this.$route.name }
  },
  computed: {
        user () {
        return this.$store.getters['auth/user']
        },
        org () {
        return this.$store.getters['org/org']
        }
    },
  methods: {
    pushRoute(routeName){
        this.activeMenu = routeName
        this.$router.push({name: routeName})
    }
  }
};
</script>
<style lang="">
</style>