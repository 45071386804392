<template lang="">
<div class="mt-2 flex justify-center w-full bg-white overflow-hidden sm:rounded-lg w-full max-w-7xl">
  <div class="sm:p-2 flex flex-wrap">
    <div class="w-full mt-6 ">
      <div class="text-center">
          <h1 class="my-3 text-2xl font-semibold text-gray-800 ">Create Company</h1>
      </div>
      <div class="sm:m-7 text-black">
          <form action="" @submit.prevent="createOrg">
              <div class="mb-4">
                <label class="block text-gray-700  mb-2" for="username">
                  Name
                </label>
                  <input v-model="name" type="text" name="name" id="name" placeholder="Company name" required class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-0 focus:outline-none focus:ring focus:ring-gray-300 rounded" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 mb-2" for="username">
                  Country
                </label>
                <select v-model="country" name="country" id="country" required class="w-full px-3 py-2 border border-gray-300 bg-white rounded">
                  <option v-for="org in countries" :key="org.code" :value="org.code">{{ org.name }}</option>
                </select>
              </div>
              <div class="mb-6 flex justify-center">
                  <button type="submit" class="w-full p-2 border-eastern-blue-500 bg-eastern-blue-500 hover:bg-eastern-blue-600  text-white border rounded">
                    <span v-show="!showSvg">Create</span>
                    <svg v-show="showSvg" class="h-5 w-5 mx-auto animate-spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
                  </button>
                  
              </div>
              <p v-if="errors.length" class="text-center text-sm text-red-500">{{ errors }}</p>
          </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { countries } from '../Countries'
export default {
    name: 'createOrg',
    data(){
      return {
        name: '',
        errors: [],
        country: '',
        countries: countries,
        showSvg: false
      }
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters['auth/isLoggedIn']
        },
        user () {
            return this.$store.getters['auth/user']
        },
        org () {
            return this.$store.getters['org/org']
        }
    },
    methods: {
      createOrg() {
        this.errors = [];

        if (this.name === '') {
          this.errors.push('Name is required.');
          return false
        }
        this.showSvg = true
        this.$store.dispatch('org/createOrg', {name: this.name, country: this.country}).then(() => {
          this.$router.push('dashboard')
        }).catch(error => {
          this.showSvg = false
          if (!error.response) {
            this.error = 'Something went wrong!. Please try again.'
          } else if (error.response.status === 401) {
            this.error = error.response.data.detail
          } else {
            this.error = 'Something went wrong!. Please try again.'
          } 
        })
      }
    }
}
</script>
<style lang="">
    
</style>