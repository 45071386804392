<template lang="">
  <div class="mx-auto">
    <div class="container px-5 py-24  bg-white ">
      <div class="lg:w-3/5 mx-auto  md:w-1/2 md:pr-16 lg:pr-0 pr-0 text-center">
        <h1 class="title-font font-medium text-3xl text-gray-900">Please check your mail to confirm your account.</h1>
        <p class="leading-relaxed mt-4 text-gray-800"> Check your email to verify your account. Click <a class="text-eastern-blue-500 cursor-pointer" @click="resendVerificationMail">here</a> to resend activation mail.</p>
      </div>
      <div class="mt-12 lg:w-3/5 mx-auto  md:w-1/2 md:pr-16 lg:pr-0 pr-0 text-center">
          <p class="block">Click the link to login if already verified.</p>
          <button class="text-white bg-eastern-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-eastern-blue-600 rounded text-lg" @click="$router.push('/login')">Login</button>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  methods: {
    resendVerificationMail() {
      this.$store.dispatch('auth/resendVerificationEmail')
    }
  }
}
</script>
<style lang="">
    
</style>