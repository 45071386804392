<template>
    
    <section id="pricing" class=" text-gray-600 body-font overflow-hidden w-full" :class="$router.currentRoute.name == 'plans' ? 'xl:w-full' : ''">
  <div class="container h-full flex flex-col justify-center px-5 mx-auto">
    <div class="flex flex-col text-center w-full mb-16">
      <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
    </div>
    <div class="flex flex-1 flex-wrap -m-4 justify-center">
      <template v-for="(plan, index) in plans.results">
        <div :key="plan.price_id" class="p-4 w-full max-w-sm">
          <div @click="setAcitivePricing(index)" class="h-full p-6 rounded-lg border-2 flex flex-col relative overflow-hidden cursor-pointer" :class="{ 'border-eastern-blue-500' : activePricing == index }">
            <span v-if="plan.billing_type === 'MONTHLY' && plan.type === 'PREPAID'" class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">1 Month</span>
            <span v-else-if="plan.billing_type === 'YEARLY' && plan.type === 'PREPAID'" class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">12 Months (Save {{ plan.currency_symbol }}1000)</span>
            <span v-else class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">{{ plan.billing_type }}</span>
            <span v-if="plan.type === 'PREPAID'" class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute left-0 top-0 rounded-br">One Time</span>
            <span v-else class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute left-0 top-0 rounded-br">{{ plan.type }}</span>
            <!-- <span class="bg-eastern-blue-500 text-white px-3 py-1 tracking-widest text-xs absolute left-0 top-0 rounded-br">{{ plan.type }}</span> -->
            <h2 class="mt-2 text-sm tracking-widest title-font mb-1 font-medium uppercase">{{ plan.plan.name }}</h2>
            <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
              <span>{{ plan.currency_symbol }}{{ parseInt(plan.amount) }}</span>
              <span v-if="plan.type === 'SUBSCRIPTION'" class="text-lg ml-1 font-normal text-gray-500">/user/{{ plan.billing_type.toLowerCase()}}</span>
              <span v-if="plan.type === 'PREPAID'" class="text-lg ml-1 font-normal text-gray-500">/user</span>
            </h1>
            <p class="flex items-center text-gray-600 mb-2">
              <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>Multi User
            </p>
            <p class="flex items-center text-gray-600 mb-2">
              <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>All features
            </p>
            <p class="flex items-center text-gray-600 mb-2">
              <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>Free updates
            </p>
            <button @click="goToPage(plan.price_id)" class="flex items-center mt-auto bg-eastern-blue-500 text-white border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded" :class="{ 'bg-eastern-blue-500 hover:bg-eastern-blue-500' : activePricing == index }"><span v-if="plan.plan.type == 'SUBSCRIPTION'">Subscribe</span><span v-else>Buy Now</span>
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</section>
   
</template>

<script>
export default {
  name: 'Pricing',
  data() {
      return {
        pricingType: 'MONTHLY',
        activePricing: 0
      }
  },
  computed: {
    plans() {
      return this.$store.getters['checkout/plans']
    }
  },
  created(){
    this.getPlans()
  },
  methods: {
    setPricingType(type){
      this.pricingType = type
    },
    setAcitivePricing(el) {
      this.activePricing = el;
    },
    goToPage(price_id){
      // this.submitClick(price_id)
      this.$router.push({ name: 'checkout', params: { priceId: price_id } })
    },
    getPlans() {
      this.$store.dispatch('checkout/getPlans')
    }
  }
}
</script>