import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import checkout from './modules/checkout'
import org from './modules/org'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        auth,
        checkout,
        org
    },
    strict: debug
})