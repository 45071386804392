<template>
  <div class="container mx-auto">
    <div class="mt-20 max-w-md mx-auto my-10">
        <div class="text-center">
            <h1 class="my-3 text-3xl font-semibold dark:text-gray-200">Change Organisation</h1>
        </div>
        <div class="m-7">
            <form action="" @submit.prevent="login">
                <div class="mb-6">
                    <select v-model="selectedOrg" @change="orgChange($event)" name="org" id="org" required class="w-full px-3 py-2 placeholder-gray-100 cursor-pointer border border-gray-300 rounded-sm focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500">
                      <option v-for="org in orgs" :key="org.org.id" :value="org.org.id">{{ org.org.name }}</option>
                    </select>
                </div>
                <p v-if="error" class="text-center text-sm text-red-500">{{ error }}</p>
            </form>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      selectedOrg: '',
      error: ''
    }
  },
  computed: {
    orgs() {
      return this.$store.getters['auth/orgs']
    }
  },
  created(){
    this.$store.dispatch('auth/getOrgs').then((response)=> {
      this.selectedOrg = response.data.filter(function (el) {return el.default === true})[0].org.id
    })
  },
  methods: {
    orgChange(event) {
      this.$store.dispatch('org/setOrg', {org: event.target.value, default: true}).then(()=> {
        this.$router.push('dashboard')
      })
    }
  }
}
</script>