
<template lang="">
  <div v-if="org" class="mt-2 flex flex-col w-full bg-white sm:rounded-lg">
    <div class="p-2">
      <div class="lg:pb-0 mt-6 lg:mt-0">
        <div class="">
          <div class="">
            <h1 class=" text-xl font-bold">{{ org.org.name }}</h1>
          </div>
        </div>
        
        
      </div>
    </div>
    <div v-if="org.role == 'ADMIN'" class="lg:flex mt-2">
      <div class="p-2 flex-1">
        <h1 class="font-semibold text-lg">Users</h1>
        <div class="p-2 relative rounded-lg overflow-x-auto  border border-gray-200 sm:rounded-lg z-10">
            <table class="min-w-full divide-y ">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Email
                  </th>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Role
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white  ">
                <tr :key="index" v-for="(userOrg, index) in org.org.users">
                  <td class="px-2 sm:px-6 py-2 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="">
                        <div class="text-sm font-medium text-gray-900">
                          {{ userOrg.user.first_name }} {{ userOrg.user.last_name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-2 sm:px-6 py-2 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="">
                        <div class="text-sm text-gray-500">
                          {{ userOrg.user.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-2 sm:px-6 py-2 whitespace-nowrap">
                    <span v-if="userOrg.active" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Active
                    </span>
                    <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-black">
                      Inactive
                    </span>
                  </td>
                  <td class="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ userOrg.role }}
                  </td>
                  <td>
                    <div class="text-right">
                      <button @click="showPopOver(userOrg, $event)" id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 " type="button"> 
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
        </div>
      </div>
      <div v-bind:style="previewPosition" v-if="showUserDropDown" class="bg-white  shadow absolute z-20 rounded-lg border">
        <div class="arrow">

          <ul class="text-sm text-gray-700" aria-labelledby="dropdownMenuIconButton">
                <li v-if="!popoverOrg.active">
                  <button type="button" @click="activateUserOrg(popoverOrg.id)" class="block px-5 py-2 hover:bg-gray-100  text-gray-900 text-left w-full">Activate</button>
                </li>
                <li v-else-if="popoverOrg.role !== 'ADMIN'">
                  <button type="button" @click="deactivateUserOrg(popoverOrg.id)" class="block px-5 py-2 hover:bg-gray-100  text-gray-900 text-left w-full">Deactivate</button>
                </li>
                <li v-if="popoverOrg.role !== 'ADMIN'">
                  <button type="button" @click="removeUserOrg(popoverOrg.id)" class="block px-5 py-2 hover:bg-gray-100  text-red-500 text-left w-full">Remove</button>
                </li>
              </ul>

        </div>
      </div>
      <div class="mt-2 sm:mt-0 p-2 flex-1">
        <h1 class="font-semibold text-lg">Pending Invites</h1>
        <div class="sm:p-2 mb-2">
          <form @submit.prevent="inviteUser" method="post" class="flex justify-between items-center">
            <input v-model="email" class="p-2 py-1 border foucs:ring-1 focus:ring-eastern-blue-500 w-3/4 rounded" type="email" placeholder="Enter Email">
            <small :key="error" v-for="error in errors" class="text-red-500">{{ error }}</small>
            <button class="ml-2 p-2 py-1 border-eastern-blue-500 bg-eastern-blue-500 hover:bg-eastern-blue-600 text-white  border w-1/4 rounded">Invite</button>
          </form>
        </div>
        <div class="sm:p-2 overflow-x-hidden border-gray-200 sm:rounded-sm">
            <table class="min-w-full divide-y ">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Email
                  </th>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="px-2 sm:px-6 py-2 text-left text-xs font-medium  uppercase tracking-wider">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white  ">
                <tr :key="index" v-for="(invite, index) in invites">
                  <td class="px-2 sm:px-6 py-2 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="">
                        <div class="text-sm font-medium text-gray-900">
                          {{ invite.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-2 sm:px-6 py-2 whitespace-nowrap">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {{ invite.status }}
                    </span>
                  </td>
                  <td class="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ invite.created_on }}
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
      </div>
    </div>
      
    
  </div>
</template>
<script>

export default {
    name: 'organisation',
    data(){
      return {
        email: '',
        errors: [],
        showUserDropDown: false,
        inviteIndex: null,
        popoverOrg: null,
        previewPosition: {
          top: '0px',
          left: '0px'
        },
        today: new Date().getDate()
      }
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters['auth/isLoggedIn']
        },
        user () {
            return this.$store.getters['auth/user']
        },
        org () {
            return this.$store.getters['org/org']
        },
        invites () {
            return this.$store.getters['org/invites']
        },
        
    },
    methods: {
      inviteUser() {
        this.errors = [];

        if (this.email === '') {
          this.errors.push('Email is required.');
          return false
        }
        this.$store.dispatch('org/inviteUser', {
          email: this.email,
          orgId: this.org.org.id
        }).then(() => {
          this.$store.dispatch("org/getInvites");
        }).catch(error => {
        })
      },
      goToPage(){
        this.$router.push({ name: 'checkoutAddSeats', params: { priceId: this.org.org.current_plan.price_id } })
      },
      goToCustomerPortal(){
            this.$store.dispatch('checkout/createPortal').then((response) => {
                window.location.href = response.data.url
            })
      },
      removeUserOrg(id){
        this.$store.dispatch('org/removeUser', id).then(() => {
          this.$store.dispatch("org/getOrg");
          this.showUserDropDown = false
        })
      },
      deactivateUserOrg(id){
        this.$store.dispatch('org/deactivateUser', {userOrgId: id, active: false}).then(() => {
          this.$store.dispatch("org/getOrg");
          this.showUserDropDown = false
        })
      },
      activateUserOrg(id){
        this.$store.dispatch('org/activateUser', {userOrgId: id, active: true}).then(() => {
          this.$store.dispatch("org/getOrg");
          this.showUserDropDown = false
        }).catch(error => this.showUserDropDown = false)
      },
      close (e) {
        if (!this.$el.contains(e.target)) {
          this.showUserDropDown = false
          this.inviteIndex = null
        }
      },
      showPopOver(userOrg, e){
            let positionTop = e.target.getBoundingClientRect().top + window.scrollY;
            let positionLeft = e.target.getBoundingClientRect().left + window.scrollX;
            this.previewPosition.top = positionTop + 35  + 'px';
            this.previewPosition.left = positionLeft - 90 + 'px';
          this.showUserDropDown = !this.showUserDropDown
          this.popoverOrg = userOrg
      },
    },
    created() {
      this.$store.dispatch("org/getInvites");
    },
    mounted () {
      document.addEventListener('click', this.close)
    },
}
</script>
<style lang="">
    
</style>