<template lang="">
    <section class="bg-white text-gray-600 body-font">
    <div v-if="success" class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Invite Accepted.</h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Invite Accepted successfully. Please click the link to login or register.</p>
        </div>
        <div class="flex lg:w-2/3 w-full sm:flex-row mx-auto px-8 justify-center sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
            <button class="text-white bg-blue-700 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg" @click="$router.push('/login')">Login</button>
        </div>
    </div>
    <div v-if="failed" class="flex justify-center container px-5 py-24 mx-auto">
        <p class="text-gray-800 text-xl">Something went wrong!.</p>
    </div>
    </section>
</template>
<script>
export default {
  data() {
    return {
        success: false,
        failed: false,
    }
  },
  created() {
    this.$store.dispatch('auth/acceptInvite', {
        uid: this.$route.params.uid,
        token: this.$route.params.token
    }).then(() => {
        this.success = true
    }).catch(error => {
        console.log(error)
        this.failed = true
    })
  },
}
</script>
<style lang="">
    
</style>