import axios from 'axios'
import store from '../store'
import router from '../routes'

import Vue from "vue";



const apiURL = process.env.NODE_ENV === 'development'
  ? `http://localhost:8000/api/v1`
  : `https://api.getalkaid.com/api/v1`


const instance = axios.create({
  baseURL: (process.env.VUE_APP_BASE_URL !== undefined) ? process.env.VUE_APP_BASE_URL : apiURL
})

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
instance.interceptors.request.use(
  (config) => {
    let token = store.getters['auth/accessToken']

    if (token && !['/users/token/', '/users/', '/users/token/refresh/'].includes(config.url) ) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    config.withCredentials = true
    
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use( (response) => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    if (error.response.status == 403 && error.response.data.detail === 'no_default_org'){
      router.push({name: 'createOrg'})
    }
    if(error.response.data.detail && error.response.data.detail !== 'no_default_org'){
      Vue.$toast.error(error.response.data.detail);
    }
    
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url == '/users/token/refresh/' || error.response.message == 'Account is disabled.' || error.config.url == '/users/token/') {
    
    store.commit('auth/unsetToken')
    // router.push({ name: 'login' });

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return store.dispatch('auth/refreshToken').then(
    (tokenResp) => {

      // New request with new token
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${tokenResp.data.access}`;

      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      })
    }).catch((error) => {
      Promise.reject(error);
    });
  
});

export default instance
