<template lang="">
    <div>
        
    </div>
</template>
<script>
export default {
    name: 'billing',
    created() {
        this.$store.dispatch('checkout/createPortal').then((response) => {
            window.location.href = response.data.url;
        })
    }
}
</script>
<style lang="">
    
</style>