import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Register from './components/Register.vue'
import Login from './components/Login.vue'
import DownloadDetail from './components/DownloadDetail.vue'
import ActivateAccount from './components/ActivateAccount.vue'
import VerifyEmail from './components/VerifyEmail.vue'
import ResetPassword from './components/ResetPassword.vue'
import ResetPasswordConfirm from './components/ResetPasswordConfirm.vue'
import ChangeOrg from './components/ChangeOrg.vue'
import CreateOrg from './components/CreateOrg.vue'

import Dashboard from './components/Dashboard.vue'
import DashboardHome from './components/Home.vue'
import Plans from './components/Plans.vue'
import PaymentSuccess from './components/PaymentSuccess.vue'
import PaymentFail from './components/PaymentFail.vue'
import Billing from './components/Billing.vue'
import Organisation from './components/Organisation.vue'
import Users from './components/Users.vue'
import Profile from './components/Profile.vue'
import Settings from './components/Settings.vue'
import Checkout from './components/Checkout.vue'
import AddSeats from './components/AddSeats.vue'

import InviteAccept from './components/InviteAccept.vue'
import PaymentRedirect from './components/PaymentRedirect.vue'

import Terms from './components/Terms.vue'
import Privacy from './components/Privacy.vue'
import Support from './components/Support.vue'
import TestPage from './components/TestPage.vue'

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/signup',
      component: Register,
      name: 'signup',
      meta: { title: 'Register | Alkaid' }
    },
    {
      path: '/login',
      component: Login,
      name: 'login',
      meta: { title: 'Login | Alkaid' }
    },
    { 
      path: '/',
      component: Dashboard,
      children: [
        {
          path: '',
          component: DashboardHome,
          name: 'dashboard',
          meta: { requiresAuth: true, title: 'Dashboard | Alkaid' }
        },
        {
          path: 'organisation',
          component: Organisation,
          name: 'organisation',
          meta: { requiresAuth: true, requiresOrg: true, title: 'Organisation | Alkaid' }
        },
        {
          path: 'users',
          component: Users,
          name: 'users',
          meta: { requiresAuth: true, requiresOrg: true, requiredRole: 'ADMIN', title: 'Users | Alkaid' }
        },
        {
          path: 'billing',
          component: Billing,
          name: 'billing',
          meta: { requiresAuth: true, title: 'Billing | Alkaid' }
        },
        {
          path: 'profile',
          component: Profile,
          name: 'profile',
          meta: { requiresAuth: true, title: 'Profile | Alkaid' }
        },
        {
          path: 'settings',
          component: Settings,
          name: 'settings',
          meta: { requiresAuth: true, requiresOrg: true, requiredRole: 'ADMIN', title: 'Settings | Alkaid' }
        },
        {
          path: 'plans',
          component: Plans,
          name: 'plans',
          meta: { requiresAuth: true, requiresOrg: true, requiredRole: 'ADMIN', title: 'Plans | Alkaid' }
        },
        {
          path: 'payment/success',
          component: PaymentSuccess,
          name: 'paymentSuccess',
          meta: { requiresAuth: true, requiredRole: 'ADMIN', title: 'Payment success | Alkaid' }
        },
        {
          path: 'payment/fail',
          component: PaymentFail,
          name: 'paymentFail',
          meta: { requiresAuth: true, requiredRole: 'ADMIN', title: 'Payment fail | Alkaid' }
        },
        {
          path: 'checkout/:priceId',
          component: Checkout,
          name: 'checkout',
          meta: { requiresAuth: true, requiresOrg: true, requiredRole: 'ADMIN', title: 'Checkout | Alkaid' }
        },
        {
          path: 'checkout/addseats/:priceId',
          component: AddSeats,
          name: 'checkoutAddSeats',
          meta: { requiresAuth: true, requiresOrg: true, requiredRole: 'ADMIN', title: 'Add seats | Alkaid' }
        },
        {
          path: 'download/desktop/:platform',
          component: DownloadDetail,
          name: 'downloadDetail',
          meta: { requiresAuth: true, title: 'Download App | Alkaid' }
        },
        {
          path: '/change-org',
          component: ChangeOrg,
          name: 'changeOrg',
          meta: { requiresAuth: true, title: 'Change Organisation | Alkaid' }
        },
        {
          path: 'create-org',
          component: CreateOrg,
          name: 'createOrg',
          meta: { requiresAuth: true, title: 'Organisation | Create Organisation' }
        },
      ]
    },
    {
      path: '/test',
      component: TestPage,
      name: 'test'
    },
    {
      path: '/activate/:uid/:token',
      component: ActivateAccount,
      name: 'activateAccount',
      meta: { title: 'Activate | Alkaid' }
    },
    {
      path: '/verify-email/',
      component: VerifyEmail,
      name: 'verifyEmail',
      meta: { title: 'Verify Email | Alkaid' }
    },
    {
      path: '/reset-password/',
      component: ResetPassword,
      name: 'resetPassword',
      meta: { title: 'Reset Password | Alkaid' }
    },
    {
      path: '/password-reset/:uid/:token',
      component: ResetPasswordConfirm,
      name: 'resetPasssordConfirm',
      meta: { title: 'Password Reset | Alkaid' }
    },
    {
      path: '/invite-accept/:uid/:token',
      component: InviteAccept,
      name: 'inviteAccept',
      meta: { title: 'Accept Invite | Alkaid' }
    },
    {
      path: '/payment_redirect',
      component: PaymentRedirect,
      name: 'paymentRedirect',
      meta: { title: 'Payment Redirect | Alkaid' }
    },
    {
      path: '/terms',
      component: Terms,
      name: 'terms',
      meta: { title: 'Terms | Alkaid' }
    },
    {
      path: '/privacy',
      component: Privacy,
      name: 'privacy',
      meta: { title: 'Privacy | Alkaid' }
    },
    {
      path: '/support',
      component: Support,
      name: 'support',
      meta: { title: 'Support | Alkaid' }
    },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  if (['login', 'signup', 'home'].includes(to.name)) {
    // if authenticated redirect to dashboard
    if (!store.getters['auth/isLoggedIn']) {
      store.dispatch('auth/refreshToken').then(() => {
        if (store.getters['auth/isLoggedIn']) next({ name: 'dashboard' })
        else next()
      }).catch((error) => {
        console.log(error)
        next()
      });
    } else {
      next({ name: 'dashboard' })
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // for protected routes, refresh authentication and redirect to login if not authenticated.
    if (!store.getters['auth/isLoggedIn']) {
      store.dispatch('auth/refreshToken').then(() => {
        if (store.getters['auth/isLoggedIn']) {
          if (store.getters['auth/user'].email_verified) {
            if (to.matched.some(record => record.meta.requiresOrg)) {
              store.dispatch('org/getOrg').then(() => {
                if (store.getters['org/org']){
                  if (to.matched.some(record => record.meta.requiredRole == 'ADMIN')) {
                    if (store.getters['org/org'].role === 'ADMIN') next()
                    else next({ name: 'dashboard' })
                  } else {
                    next()
                  }
                } else {
                  next({ name: 'createOrg' })
                }
              })
            } else {
              next()
            }
          } else {
            next({ name: 'verifyEmail' })
          }
        } else {
          next({ name: 'login' })
        }
      }).catch((error) => {
        console.log(error)
        next({ name: 'login' })
      });
    } else {
      if (store.getters['auth/user'].email_verified) {
        if (to.matched.some(record => record.meta.requiresOrg)) {
          store.dispatch('org/getOrg').then(() => {
            if (store.getters['org/org']){
              if (to.matched.some(record => record.meta.requiredRole == 'ADMIN')) {
                if (store.getters['org/org'].role === 'ADMIN') next()
                else next({ name: 'dashboard' })
              } else {
                next()
              }
            } else {
              next({ name: 'createOrg' })
            }
          })
        } else {
          next()
        }
      } else {
        next({ name: 'verifyEmail' })
      }
    }
  } else {
    // public routes
    next()
  }
})

const DEFAULT_TITLE = 'Alkaid';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;