import axios from './http'

import { getCookie } from './utils.js';

export default {
  register (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/',
        payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  createOrg (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/orgs/',
        payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  addUserToOrg (orgId, payload) {
    return new Promise((resolve, reject) => {
      axios.put(
        '/orgs/'+ orgId + '/users/', payload
      ).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  login (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/token/',
        payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getProfile () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateProfile (payload) {
    return new Promise((resolve, reject) => {
      axios.patch(
        '/users/me/',
        payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getOrgs () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/orgs/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAppVersion () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/config/app/latest/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  refreshToken () {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/token/refresh/', {}, {
          headers: {
            'X-CSRFToken': getCookie('csrftoken')
          }
        }
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/logout/',
        {},{
          headers: {
            'X-CSRFToken': getCookie('csrftoken')
          }
        }
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  activateAccount (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/activation/',
        { uid: payload.uid, token: payload.token }
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resendActivationEmail () {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/resend-activation-mail/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendResetEmail (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/reset-password/', {email: payload.email}
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setNewPassword (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/reset-password-confirm/', payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDefaultOrg () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/orgs/default/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  acceptInvite (payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/users/invite-accept/',
        { uid: payload.uid, token: payload.token }
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInvites () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/invites/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  inviteUpdate ({id, ...payload}) {
    return new Promise((resolve, reject) => {
      axios.patch(
        `/users/me/invites/${id}/`,
        payload
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPlan () {
    return new Promise((resolve, reject) => {
      axios.get(
        '/users/me/plan/'
      ).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
