<template>
  <section class="flex items-center min-h-screen text-gray-600 body-font">
    
    <div class="container p-5 mx-auto flex flex-wrap items-center">
      
      <div class=" md:w-1/3 lg:w-1/2 xl:w-3/5 md:pr-16 lg:pr-0 pr-0">
        <h1 class="title-font font-medium text-3xl text-gray-900">Want to save time, minimise losses and maximise sales?</h1>
        <p class="leading-relaxed mt-4"> Signup today for 14 days trial, no credit card required.</p>
      </div>
      <div class=" md:w-2/3 lg:w-1/2 xl:w-2/6 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        <div class="text-center mb-3">
          <div class="flex justify-center items-center">
            <img src="@/assets/logo.png" class="object-contain h-8 w-8" alt="">
            <h1 class="ml-1 my-3 text-3xl font-semibold text-gray-800">Alkaid</h1>
          </div>
            <p class="text-gray-500 dark:text-gray-400">Sign up for your account</p>
        </div>
        <form @submit.prevent="register">
          <div class="relative mb-4 flex flex-col sm:flex-row">
            <div class="">
              <label for="email" class="leading-7 text-sm text-gray-600">First Name</label>
              <input required v-model="first_name" type="text" id="first_name" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-eastern-blue-500 focus:ring-2 focus:ring-eastern-blue-50 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
            <div class="sm:ml-2">
              <label for="email" class="leading-7 text-sm text-gray-600">Last Name</label>
              <input required v-model="last_name" type="text" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-eastern-blue-500 focus:ring-2 focus:ring-eastern-blue-50 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
          </div>
          <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input required v-model="email" type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-eastern-blue-500 focus:ring-2 focus:ring-eastern-blue-50 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
          <div class="relative mb-4">
            <label for="password" class="leading-7 text-sm text-gray-600">Password</label>
            <input required v-model="password" type="password" id="password" name="password" class="w-full bg-white rounded border border-gray-300 focus:border-eastern-blue-500 focus:ring-2 focus:ring-eastern-blue-50 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
          </div>
          <button class="flex items-center justify-center text-white bg-eastern-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-eastern-blue-600 rounded text-lg">
            <svg v-show="showSvg" class="h-5 w-5 mx-auto animate-spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
            <span v-show="!showSvg">Submit</span></button>
        </form>
        <div class="text-center text-xs mt-3 text-gray-500">
          By proceeding, you agree to our <a class="underline" :href="'/terms'">Terms of Use</a>  and <a class="underline" :href="'/privacy'">Privacy Policy</a>
        </div>
        <p class="mt-4 text-sm text-center text-gray-500">Already have an account? <a href="/signin" class="text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Log in</a>.</p>
      </div>
    </div>
    
  </section>
</template>

<script>
export default {
  name: 'register',
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      org: '',
      country: '',
      showSvg: false
    }
  },
  computed: {
      countries () {
        return this.$store.getters['org/countries']
      }
    },
  created(){
    this.$store.dispatch('org/getCountries')
  },
  methods: {
      register(){
        this.showSvg = true
        this.$store.dispatch('auth/register', {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password
        }).then(() => {
          this.$router.push('login')
        }).catch(() => {
          this.showSvg = false
        })
      }
    }
}
</script>