import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import store from './store'
import router from './routes'
import App from './components/App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faRepeat } from '@fortawesome/free-solid-svg-icons'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Fragment from 'vue-fragment';

Vue.use(Fragment.Plugin);

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Toast)

library.add(faRepeat)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
