<template lang="">
    <div class="h-full w-full">
        <div class="flex h-full justify-center items-center">
            <svg class="h-10 w-10 bg-eastern-blue-500 mx-auto animate-spin rounded-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
        </div>
    </div>

</template>
<script>
export default {
    methods: {
      checkStatus(order_id) {
        this.$store.dispatch('checkout/checkStatus', {
          order_id: order_id
        }).then((response) => {
          if(response.data.status == 'success') {
              this.$router.push({name: 'paymentSuccess'})
          } else {
              this.$router.push({name: 'paymentFail'})
          }
          
        }).catch(error => {
          if (!error.response) {
            this.error = 'Something went wrong!'
          } else if (error.response.status === 401) {
            this.error = error.response.data.detail
          } else {
            this.error = 'Something went wrong!. Please try again.'
          } 
          this.$router.push({name: 'paymentFail'})
        })
      }
    },
    created(){
        this.checkStatus(this.$route.query.oid)
    }
}
</script>
<style lang="">
    
</style>