import checkout from '../../api/checkout'

// initial state
const state = {
  plans: [],
  plan: null
}

// getters
const getters = {
  plans(state) {
    return state.plans
  },
  plan(state) {
    return state.plan
  }
}

// actions
const actions = {
  createOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.createOrder(payload).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPlans({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.getPlans(payload).then((response) => {
        commit('setPlans', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPlan({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.getPlan(payload).then((response) => {
        commit('setPlan', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  confirmOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.confirmOrder(payload).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  checkStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.checkStatus(payload).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createPortal({ commit }, payload) {
    return new Promise((resolve, reject) => {
      checkout.createPortal(payload).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

// mutations
const mutations = {
  setPlans(state, data) {
    state.plans = data
  },
  setPlan(state, data) {
    state.plan = data
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
