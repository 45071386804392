<template>
  <div class="flex items-center min-h-screen text-black bg-white dark:bg-gray-900">
      <div class="container mx-auto">
        <div v-if="success" class="mx-auto my-10">
          <div class="text-center">
            <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Reset Password link suceessfully sent your email.</h1>
          </div>
          <div class="m-7">
            <p class="text-sm text-center text-gray-400">Back to <a href="/login" class="text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Login</a>.</p>
          </div>
        </div>
        <div v-else class="max-w-md mx-auto my-10">
          <div class="text-center">
            <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Reset Password</h1>
          </div>
          <div class="m-7">
            <form action="" @submit.prevent="sendResetLink">
              <div class="mb-6">
                <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                <input v-model="email" required type="email" name="email" id="email" placeholder="you@company.com" class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
              </div>
              <div class="mb-6">
                <button type="submit" class="w-full px-3 py-4 text-white bg-eastern-blue-500 rounded-md hover:bg-eastern-blue-600 focus:bg-eastern-blue-600 focus:outline-none">Send reset link</button>
              </div>
              <p class="text-sm text-center text-gray-400">Back to <a href="/login" class="text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Login</a>.</p>
              <p v-if="error" class="text-center text-sm text-red-500">{{ error }}</p>
            </form>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'login',
  data() {
      return {
          email: '',
          password: '',
          error: '',
          success: false
      }
  },
  methods: {
      sendResetLink(){
        this.$store.dispatch('auth/sendResetLink', {
          email: this.email
        }).then(() => {
          this.success = true
        }).catch(error => {
          if (!error.response) {
            this.error = 'Something went wrong!. Please try again.'
          } else if (error.response.status === 400) {
            this.error = error.response.data.detail
          } else {
            this.error = 'Something went wrong!. Please try again.'
          }
        })
      }
  }
}
</script>