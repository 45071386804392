<template>
    <div>
        <div class="md:h-16 py-6 md:py-10">

        </div>
        <div class="flex flex-col items-center md:px-20 py-32 md:py-72 md:h-auto justify-center bg-gray-100">
            <h2 class="px-4 pb-10 text-4xl md:text-5xl font-mono text-gray-700">Alkaid Desktop is downloading</h2>
            <span class="px-4 text-gray-800">If the download does not start within 5 seconds, click <span @click="downloadItem(url, '0.0.2')" class="text-green-500 cursor-pointer">here</span>.</span>
        </div>
    </div>
</template>
<script>

export default {
  name: 'downloadforos',
  data() {
      return {
          url: null
      }
  },
  created() {
      document.title = "Thank you for downloading Alkaid";
      if (this.$route.params.platform === 'windows') {
        this.url = 'https://github.com/getalkaid/alkaid-desktop/releases/download/v0.0.2/alkaid-setup-0.0.2.exe'
      } else if (this.$route.params.platform === 'linux') {
        this.url = 'https://github.com/getalkaid/alkaid-desktop/releases/download/v0.0.2/alkaid_0.0.2_amd64.deb'
      }

      if (this.url) {
        this.downloadItem(this.url, '0.0.2')
      }
  },
  methods: {
    downloadItem (url, label) {
      console.log(label)
      setTimeout(() => {
        const a = document.createElement('a')
        a.href = url
        a.download = true
        a.click()
      }, 1000)
    }
  }
}
</script>
<style lang="">
    
</style>