import auth from '../../api/auth'

// initial state
const state = {
  token: null,
  user: {},
  orgs: [],
  invites: [],
  plan: null
}

// getters
const getters = {
  isLoggedIn(state) {
    return state.user.access !== null && state.user.access !== undefined
  },
  user(state) {
    return state.user
  },
  accessToken(state) {
    return state.user.access
  },
  orgs(state) {
    return state.orgs
  },
  invites(state) {
    return state.invites
  },
  plan(state) {
    return state.plan
  }
}

// actions
const actions = {
  register({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.register(payload).then((response) => {
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
    
  },
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.login(payload).then((response) => {
        // commit('setToken', response.data.access)
        commit('setUser', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  refreshToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.refreshToken(payload).then(response => {
        // commit('setToken', response.data.access)
        commit('setUser', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout({commit}) {
    return new Promise((resolve, reject) => {
      auth.logout().then(response => {
        // commit('unsetToken')
        commit('unsetUser')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getProfile({commit}) {
    return new Promise((resolve, reject) => {
      auth.getProfile().then(response => {
        commit('setUser', response.data)
        resolve(response)
      }).catch(error => {
        commit('unsetToken')
        commit('unsetUser')
        reject(error)
      })
    })
  },
  updateProfile({commit}, payload) {
    return new Promise((resolve, reject) => {
      auth.updateProfile(payload).then(response => {
        commit('setUserProfile', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getOrgs({commit}) {
    return new Promise((resolve, reject) => {
      auth.getOrgs().then(response => {
        commit('setOrgs', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAppVersion({commit}) {
    return new Promise((resolve, reject) => {
      auth.getAppVersion().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  activateAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.activateAccount(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resendVerificationEmail({ commit }) {
    return new Promise((resolve, reject) => {
      auth.resendActivationEmail().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendResetLink({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.sendResetEmail(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setNewPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.setNewPassword(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  acceptInvite({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.acceptInvite(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInvites({commit}) {
    return new Promise((resolve, reject) => {
      auth.getInvites().then(response => {
        commit('setInvites', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  inviteUpdate ({commit}, payload) {
    return new Promise((resolve, reject) => {
      auth.inviteUpdate(payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPlan({commit}) {
    return new Promise((resolve, reject) => {
      auth.getPlan().then(response => {
        commit('setPlan', response.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

// mutations
const mutations = {
  setToken(state, access) {
    state.token = access
  },
  unsetToken(state) {
    state.token = null
  },
  setUser(state, data) {
    state.user = data
  },
  setUserProfile(state, data) {
    state.user.first_name = data.first_name
    state.user.last_name = data.last_name
  },
  unsetUser(state) {
    state.user = {}
  },
  setOrgs(state, data) {
    state.orgs = data
  },
  setInvites(state, data) {
    state.invites = data
  },
  setPlan(state, data) {
    state.plan = data
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
