<template>
  <div id="app" class="leading-normal tracking-normal flex flex-col min-h-screen">
    <router-view></router-view>
  </div>
  
</template>

<script>

export default {
  name: 'app',
}
</script>

<style src="../assets/output.css" />
