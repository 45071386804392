<template lang="">
  <div class="flex w-full h-full items-center">
    <div class="bg-gray-100"></div>
    <div class="relative mx-auto px-2 sm:px-6 lg:px-8" v-if="planPrice">
        <div class="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex">
            <div v-if="planPrice" class="py-8 sm:px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                  <span class="" v-if="planPrice.currency==='INR'">₹{{ parseInt(planPrice.amount * quantity) }}</span>
                  <span class="" v-else>${{ parseInt(planPrice.amount * quantity) }}</span>
                  <span class="ml-3 text-xl font-medium text-gray-500">{{ planPrice.currency }}</span>
                </div>
                <div class="mt-3">
                  <label>No. of users:</label>
                  <input v-model="quantity" :min="1" :max="10" class="w-14 ml-2 p-2 bg-white border-2 rounded font-bold" type="number">
                </div>
                <div class="mt-6">
                    <div class="rounded-md shadow">
                        <button v-if="planPrice.type === 'PREPAID' && planPrice.currency === 'INR'" v-on:click="startPhonePeCheckout($route.params.priceId)" role="link" class="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-eastern-blue-500 hover:bg-eastern-blue-600">
                          <svg v-show="showSvg" class="h-5 w-5 mx-auto animate-spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
                          <span v-show="!showSvg">Proceed to Payment</span>
                        </button>
                        <button v-else v-on:click="startCheckout($route.params.priceId)" role="link" class="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-eastern-blue-500 hover:bg-eastern-blue-600">
                          <svg v-show="showSvg" class="h-5 w-5 mx-auto animate-spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
                          <span v-show="!showSvg">Proceed to Payment</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: 'plans',
  components: {
  },
  data(){
    return {
      quantity: 1,
      showSvg: false,
      amount: 0
    }
  },
  methods: {
    startCheckout(price_id){
      this.showSvg = true
      this.$store.dispatch('checkout/getPlan', price_id).then((response) => {
          this.$store.dispatch('checkout/createOrder', {
            plan_price: response.data.id,
            quantity: this.quantity,
            seatUpgrade: true
          }).then((response) => {
            this.redirectToStripe(response.data)
          }).catch(() => {
            this.showSvg = false
          })
      }).catch(() => {
        this.showSvg = false
      })
    },
    async redirectToStripe(data) {
      const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

      const stripe = await stripePromise;

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: data.reference_id,
      });
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result.error)
      }
    },
    startPhonePeCheckout(price_id){
      this.showSvg = true
      this.$store.dispatch('checkout/getPlan', price_id).then((response) => {
          this.$store.dispatch('checkout/createOrder', {
            plan_price: response.data.id,
            quantity: this.quantity,
            seatUpgrade: true
          }).then((response) => {
            window.location.replace(response.data.phonepe_checkout_url)
          }).catch(() => {
            this.showSvg = false
          })
      }).catch(() => {
        this.showSvg = false
      })
    },
  },
  computed: {
    planPrice() {
      return this.$store.getters['checkout/plan']
    }
  },
  created(){
    this.$store.dispatch('checkout/getPlan', this.$route.params.priceId)
    console.log(this.planPrice)
  },
}
</script>
<style lang="">
    
</style>