<template lang="">
    <div class=" h-full  ">
        <div class="h-full flex justify-center items-center">
            <div class="text-green-600 bg-green-200 p-4 sm:px-12">
                <p class="mb-2 font-bold">
                    Payment successfull
                </p>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    created(){
        let vueInst = this
        setTimeout(function(){ vueInst.$router.push({name: 'billing'}) }, 4000);
    }
}
</script>
<style lang="">
    
</style>