<template lang="">
    <Pricing></Pricing>
</template>
<script>
import Pricing from '@/components/Pricing.vue'

export default {
  name: 'plans',
  components: {
    Pricing
  },
  methods: {
  }
}
</script>
<style lang="">
    
</style>