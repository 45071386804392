<template lang="">
    <section class="mt-12 bg-gray-50 text-gray-600 body-font overflow-auto">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-wrap mx-auto lg:max-w-6xl lg:justify-center">
            <div class="my-3 w-full lg:w-1/2 lg:my-4 lg:px-4 text-center">
              <div class="">
                <div class="py-2 px-8">
                  <h2 class="text-lg font-semibold text-gray-900">Support Email</h2>
                </div>
                <div class="py-2 px-8"><p class="text-base">Any other concerns, feedback, questions, or account related issues can be sent to <a href="mailto:hello@getalkaid.com" target="_blank" class="text-inso-purple font-medium">support@getalkaid.com</a></p>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-wrap mx-auto lg:max-w-5xl lg:justify-center">
            <div class="my-5 px-1 w-full lg:w-1/2 lg:my-4 text-center">
              <div class="">
                <div class="py-2">
                  <h2 class="text-lg font-semibold text-gray-900">Contact</h2>
                </div>
                <div class="py-2">
                  <p class="text-base">68/1520, 2, CM MATHEW BROTHERS ARCADE, CHAKKORATHUKULAM, CALICUT, KERALA, INDIA 673014</p>
                  <p class="text-base">Phone: +91-9072424740 </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
        
  </section>
</template>
<script>
export default {
  name: 'support'
}
</script>
<style lang="">
    
</style>