<template>
  <div class="sm:mt-3 p-2 pt-5 w-full">
    <div class="flex justify-between items-center">
      <h1 class="text-gray-700 text-lg font-semibold">Users</h1>
      <form @submit.prevent="inviteUser" method="post" class="flex items-center">
        <label class="ml-auto mr-2 text-gray-800" for="">Invite User</label>
        <div>
          <input v-model="email" class="px-2 border rounded-sm" type="email">
          <small :key="error" v-for="error in errors" class="text-red-500">{{ error }}</small>
        </div>
        
        <button class="px-2 border bg-green-600 rounded-sm text-white">Invite</button>
      </form>
    </div>
    
    <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden border-b border-gray-200 sm:rounded-sm">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Role
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr :key="index" v-for="(userOrg, index) in org.org.users">
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="">
                      <div class="text-sm font-medium text-gray-900">
                        {{ userOrg.user.first_name }} {{ userOrg.user.last_name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="">
                      <div class="text-sm text-gray-500">
                        {{ userOrg.user.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Active
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ userOrg.role }}
                </td>
                <td v-if="userOrg.role !== 'ADMIN'" class="px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">Delete</a>
                </td>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'users',
    data(){
      return {
        email: '',
        errors: []
      }
    },
    computed: {
      user () {
        return this.$store.getters['auth/user']
      },
      org () {
        return this.$store.getters['org/org']
      }
    },
    methods: {
      inviteUser() {
        this.errors = [];

        if (this.email === '') {
          this.errors.push('Email is required.');
          return false
        }
        this.$store.dispatch('org/inviteUser', {
          email: this.email,
          orgId: this.org.org.id
        }).then(() => {
          this.$router.push('dashboard')
        }).catch(error => {
          if (!error.response) {
            this.error = 'Something went wrong!. Please try again.'
          } else if (error.response.status === 401) {
            this.error = error.response.data.detail
          } else {
            this.error = 'Something went wrong!. Please try again.'
          } 
        })
      }
    }
}
</script>

<style>

</style>