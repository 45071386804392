<template>
<fragment>
    <Header @clicked-hamburger="clickedHamburger"></Header>
    <!-- <Sidebar v-if="$router.currentRoute.name != 'changeOrg'" :isopen="showSidebar"></Sidebar> -->
    <main class="p-3 sm:p-6 flex flex-1 justify-center">
      <div class="max-w-7xl w-full" :class="$router.currentRoute.name != 'plans' ? '' : 'w-full'">
          <router-view></router-view>
      </div>
    </main>
    <Footer></Footer>
    </fragment>
</template>

<script>
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
import Footer from './Footer.vue'

export default {
  name: 'Dashboard',
  components: {
      Header,
      // Sidebar,
      Footer
  },
  data () {
    return {
      selected: 0,
      showSidebar: false
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['auth/isLoggedIn']
    },
    user () {
      return this.$store.getters['auth/user']
    },
    org () {
      return this.$store.getters['org/org']
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('auth/logout', {
      }).then(() => {
        this.$router.push('home')
      })
    },
    changeSelected(i) {
      this.selected = i
    },
    clickedHamburger(value) {
        this.showSidebar = value
    },
    getProfile() {
      this.$store.dispatch('auth/getProfile').catch(() => {
        this.$router.push('login')
      })
    }
  }
}
</script>