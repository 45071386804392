<template>
  <div class="flex items-center min-h-screen bg-white dark:bg-gray-900">
      <div class="container mx-auto">
          <div class="max-w-md mx-auto my-10 p-3 bg-gray-100">
              <div class="text-center">
                <div class="flex justify-center items-center">
                  <img src="@/assets/logo.png" class="object-contain h-8 w-8" alt="">
                  <h1 class="ml-1 my-3 text-3xl font-semibold text-gray-800">Alkaid</h1>
                </div>
                  <p class="text-gray-500 dark:text-gray-400">Sign in to access your account</p>
              </div>
              <div class="m-7">
                  <form action="" @submit.prevent="login">
                      <div class="mb-6">
                          <label for="email" class="leading-7 text-sm text-gray-600">Email Address</label>
                          <input v-model="email" type="email" name="email" id="email" placeholder="Email" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                      </div>
                      <div class="mb-6">
                          <div class="flex justify-between mb-1">
                              <label for="password" class="leading-7 text-sm text-gray-600">Password</label>
                              <a @click="$router.push({ name: 'resetPassword' })" class="leading-7 text-sm text-gray-600 cursor-pointer hover:text-eastern-blue-500">Forgot password?</a>
                          </div>
                          <input v-model="password" type="password" name="password" id="password" placeholder="Your Password" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                      </div>
                      <div class="mb-6">
                          
                          <button type="submit" class="w-full text-white bg-eastern-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-eastern-blue-600 rounded text-lg text-center">
                            <span v-show="!showSvg">Sign in</span>
                            <svg v-show="showSvg" class="h-5 w-5 mx-auto animate-spin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><path d="M12 3V6M3 12H6M5.63607 5.63604L7.75739 7.75736M5.63604 18.3639L7.75736 16.2426M21 12.0005H18M18.364 5.63639L16.2427 7.75771M11.9998 21.0002V18.0002M18.3639 18.3642L16.2426 16.2429" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
                          </button>
                      </div>
                      <p class="text-sm text-center text-gray-400">Don&#x27;t have an account yet? <a href="/signup" class="text-eastern-blue-500 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800">Sign up</a>.</p>
                      <p v-if="error" class="text-center text-sm text-red-500">{{ error }}</p>
                  </form>
              </div>
          </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'login',
  data() {
      return {
          email: '',
          password: '',
          error: '',
          showSvg: false
      }
  },
  methods: {
      login(){
        this.showSvg = true
        this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        }).then(() => {
          this.$router.push('dashboard')
        }).catch(error => {
          this.showSvg = false
          if (!error.response) {
            this.error = 'Something went wrong!. Please try again.'
          } else if (error.response.status === 401) {
            this.error = error.response.data.detail
          } else {
            this.error = 'Something went wrong!. Please try again.'
          } 
        })
      },
      resendVerificationMail() {
        this.$store.dispatch('auth/resendVerificationEmail', {email: this.email})
      }
  }
}
</script>